import axios from 'axios'
import { message } from 'antd'
import Cookies from 'universal-cookie'

const myApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
})

const cookies = new Cookies()
const token = cookies.get('token')

// TODO https://gist.github.com/mkjiau/650013a99c341c9f23ca00ccb213db1c
// TODO токен и обновление токена

myApi.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${token}`
    return config
  },
  (error) => {
    const {
      response: { data },
    } = error
    message.error(data.error.message)
    return Promise.reject(error)
  },
)

export default myApi
